export const keycloakDev = {
  url: 'https://id.stmtires.com/auth',
  realm: 'STM_Prod',
  clientId: 'STM_Customer_Portal',
};

export const keycloakProd = {
  url: 'https://id.stmtires.com/auth',
  realm: 'STM_Prod',
  clientId: 'STM_Customer_Portal',
};
