import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  digestItems: { dateTime: Date; message: string; }[];
  navButtons: { image: string; title: string; description: string; marginTop?: string; link?: string; }[];

  constructor() {}

  ngOnInit() {
    this.digestItems = [{
      dateTime: moment('2020-11-06 13:26:00', 'YYYY-MM-DD HH:mm:ss').toDate(),
      message: 'Service Request Processing',
    }, {
      dateTime: moment('2020-11-06 08:00:00', 'YYYY-MM-DD HH:mm:ss').toDate(),
      message: 'Service History Updated',
    }, {
      dateTime: moment('2020-11-06 08:00:00', 'YYYY-MM-DD HH:mm:ss').toDate(),
      message: 'New Daily Report Added',
    }, {
      dateTime: moment('2020-11-05 08:00:00', 'YYYY-MM-DD HH:mm:ss').toDate(),
      message: 'Service History Updated',
    }, {
      dateTime: moment('2020-11-05 08:00:00', 'YYYY-MM-DD HH:mm:ss').toDate(),
      message: 'New Daily Report Added',
    }, {
      dateTime: moment('2020-11-05 22:35:00', 'YYYY-MM-DD HH:mm:ss').toDate(),
      message: '2 Service Requests Placed. Down time: 1 hour.',
    }, {
      dateTime: moment('2020-11-04 08:00:00', 'YYYY-MM-DD HH:mm:ss').toDate(),
      message: 'Service History Updated',
    }, {
      dateTime: moment('2020-11-04 08:00:00', 'YYYY-MM-DD HH:mm:ss').toDate(),
      message: 'New Daily Report Added',
    }, {
      dateTime: moment('2020-11-04 09:35:00', 'YYYY-MM-DD HH:mm:ss').toDate(),
      message: 'Service Request Placed. Down time: 1.5 hours.',
    }, {
      dateTime: moment('2020-11-04 09:35:00', 'YYYY-MM-DD HH:mm:ss').toDate(),
      message: 'Service Request Placed. Down time: 1.5 hours.',
    }, {
      dateTime: moment('2020-11-04 09:35:00', 'YYYY-MM-DD HH:mm:ss').toDate(),
      message: 'Service Request Placed. Down time: 1.5 hours.',
    }];

    this.navButtons = [{
      image: 'assets/img/icons/2020_STM Portal Icons_FLEET - FLEET BREAKDOWN FORM.svg',
      title: 'Request Service',
      description: 'Schedule your service online',
    }, {
      image: 'assets/img/icons/2020_STM Portal Icons_MARKETING - DOCUMENTS.svg',
      title: 'Daily Report',
      description: 'Find all your report data in one location',
    }, {
      image: 'assets/img/icons/2020_STM Portal Icons_AP - HISTORY.svg',
      title: 'Service History',
      description: 'See Your Service History',
      link: '/service-calls/search',
    }, {
      image: 'assets/img/icons/2020_STM Icons_Phone.svg',
      title: 'Contacts',
      description: 'All your Southern Tire Mart contacts in one place',
    }];
  }
}
