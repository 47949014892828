import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WorkQueue, WorkQueueSelectedFilter, TaskType, Task } from './work-queue.interface';
import { TaskService } from '../../shared/work-queue/task.service';
import { combineAll } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-work-queue',
  templateUrl: './work-queue.component.html',
  styleUrls: ['./work-queue.component.scss'],
})
export class WorkQueueComponent implements OnInit {

  @Input() moduleName: string;
  @Input() workQueue: WorkQueue;
  @Input() defaultDayFilterValue: number = 30;
  @Output() filtersChanged = new EventEmitter();
  @Output() taskMarkedComplete = new EventEmitter();

  statusFilterOptions: {label: String, value: String}[] = [
    { label: 'Incomplete', value: 'incomplete' },
    { label: 'Incomplete, Locked', value: 'incomplete-locked' },
    { label: 'Incomplete, Unlocked', value: 'incomplete-unlocked' },
    { label: 'Complete', value: 'complete' },
  ];
  selectedStatusFilter: {label: String, value: String};

  dayFilterOptions: {label: String, value: Number}[] = [
    { label: 'All', value: -1 },
    { label: 'Today', value: 0 },
    { label: '1 Day', value: 1 },
    { label: '5 Days', value: 5 },
    { label: '10 Days', value: 10 },
    { label: '15 Days', value: 15 },
    { label: '30 Days', value: 30 },
    { label: '60 Days', value: 60 },
    { label: '90 Days', value: 90 },
  ];
  selectedDayFilter: {label: String, value: Number};

  constructor(private taskService: TaskService) { }

  ngOnInit(): void {
    this.selectedStatusFilter = this.statusFilterOptions[0];
    this.selectedDayFilter = this.dayFilterOptions.find(filter => filter.value === this.defaultDayFilterValue);
  }

  async toggleComplete (event, taskType: TaskType, task: Task) {
    task.complete = event.checked;
    const query = <Task>{ _id: task._id, complete: task.complete };
    if (task.complete) {
      query.dateCompleted = new Date();
      task.dateCompleted = query.dateCompleted;
    } else {
      query.dateCompleted = null;
      task.dateCompleted = query.dateCompleted;
    }
    await this.taskService.update(query).toPromise().then((updatedRecord) => {
      if (task.complete) this.taskMarkedComplete.emit(task);
    });
  }

  async toggleLock (event, taskType: TaskType, task: Task) {
    task.locked = event.checked;
    const query = <Task>{ _id: task._id, locked: task.locked };
    if (task.locked) {
      query.dateLocked = new Date();
      task.dateLocked = query.dateLocked;
    } else {
      query.dateLocked = null;
      task.dateLocked = query.dateLocked;
    }
    await this.taskService.update(query).toPromise();
  }

  getTaskTypeHeader (taskType) {
    const incompleteCount = taskType.tasks.filter(task => !task.complete).length;

    let header: string = '';
    if (incompleteCount > 0) {
      header = `${taskType.header} (${incompleteCount} Outstanding)`;
    } else {
      header = `${taskType.header} (All Caught Up)`;
    }

    if (taskType.isLoading || !taskType.subscription) header = `${taskType.header} (Loading..)`;

    return header;
  }

  getLockTitle (task: Task) {
    let title: string = '';

    if (task.__meta && task.__meta.updatedBy && task.locked) {
      title = `Locked By: ${task.__meta.updatedBy.name} on ${moment(task.dateLocked).format('MMMM Do YYYY h:mm:ss a')}.`;
    } else {
      title = 'Not Locked';
    }

    return title;
  }

  getCompleteTitle (task: Task) {
    let title: string = '';

    if (task.__meta && task.__meta.updatedBy && task.complete) {
      title = `Completed By: ${task.__meta.updatedBy.name} on ${moment(task.dateCompleted).format('MMMM Do YYYY h:mm:ss a')}.`;
    } else {
      title = '';
    }

    return title;
  }

  onFilterChange (event) {
    this.filtersChanged.emit(
      <WorkQueueSelectedFilter>{
        selectedStatusFilter: this.selectedStatusFilter,
        selectedDayFilter: this.selectedDayFilter,
      });
  }
}
