import { Injectable } from '@angular/core';
import { CrudBaseService } from '../shared/crud-base.service';
import { HttpClient } from '@angular/common/http';
import { ServiceCall } from './service-call.interface';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';

export interface DispatcherRule {
  name: string; // 'New Calls', 'New Calls over 5 min', etc...
  class?: string; // row-red, row-green, etc...
  priority?: 'Critical' | 'Major' | 'Minor';
  conditions: DispatcherRuleCondition[];
}

interface DispatcherRuleCondition {
  property: string | Function; // companyName, created, etc...
  operator: '>' | '<' | '===' | '<=';
  value: any; // 'Penske', '5m', etc.
  comparisonType?: 'string' | 'number' | 'time';
}

export const OPEN_STATUS_CODES = ['-3', '-1', '0', '1', '2', '3', '4', 'R'];

@Injectable({
  providedIn: 'root',
})
export class ServiceCallsService extends CrudBaseService<ServiceCall> {
  protected urlPath: string = 'service-calls';
  private selectedServiceCallBehaviorSubject = new BehaviorSubject<ServiceCall>(null);
  selectedServiceCall = this.selectedServiceCallBehaviorSubject.asObservable();
  serviceCallSearchBehaviorSubject: BehaviorSubject<ServiceCall[]> = new BehaviorSubject<ServiceCall[]>(null);
  serviceCallSearch: Observable<ServiceCall[]> = this.serviceCallSearchBehaviorSubject.asObservable();

  constructor(protected http: HttpClient) {
    super('service-calls');
  }
  createInMaddenCo(serviceCall: ServiceCall): Promise<ServiceCall> {
    const url = `${this.url}${serviceCall._id ? serviceCall._id : ''}/create-in-maddenco`.replace('//c', '/c');
    return this.http.post<ServiceCall>(url, serviceCall).toPromise();
  }

  requestServiceLocation(body: { phone: number, serviceCall: ServiceCall }): Promise<ServiceCall> {
    return this.http.post<ServiceCall>(`${this.url}${body.serviceCall._id}/request-service-location`, body).toPromise();
  }

  updateServiceLocation(body: { location: { lat: number, lng: number }, serviceCallId: string }): Promise<ServiceCall> {
    return this.http.post<ServiceCall>(`${this.url}${body.serviceCallId}/update-service-location-coordinates`, body).toPromise();
  }

  markAsCancelled(serviceCall: ServiceCall): Promise<ServiceCall> {
    return this.http.post<ServiceCall>(`${this.url}${serviceCall._id}/cancel`, serviceCall).toPromise();
  }

  setSelectedServiceCall(serviceCall: ServiceCall): void {
    this.selectedServiceCallBehaviorSubject.next(serviceCall);
  }

  getDispatcherQueue(serviceCalls: ServiceCall[]): ServiceCall[] {
    const dispatcherQueue = [];
    for (const rule of this.dispatcherTODORules) {
      for (const serviceCall of serviceCalls) {
        const alreadyInQueue = dispatcherQueue.findIndex(sc => sc._id === serviceCall._id) > -1;
        if (!alreadyInQueue) {
          if (this.isTODO(serviceCall, rule)) {
            serviceCall.dispatcherClass = rule.class;
            serviceCall.priority = rule.priority;
            dispatcherQueue.push(serviceCall);
          }
        }
      }
    }

    return dispatcherQueue;
  }

  getValue(serviceCall: ServiceCall, value: string | Function): any {
    if (value instanceof Function) {
      const func = value;
      return func(serviceCall);
    }

    const valueParts = value.split('.');
    let nestedValue = serviceCall;
    try {
      valueParts.forEach((v: any) => (nestedValue = nestedValue[v]));
    } catch (error) {
      return undefined;
    }

    return nestedValue;
  }

  isTODO(serviceCall: any, rule: DispatcherRule): boolean {
    const passing = [];

    // for (const condition of rule.conditions) {
    rule.conditions.forEach((condition: DispatcherRuleCondition) => {
      const serviceCallValue = this.getValue(serviceCall, condition.property);

      // Compare times
      if (condition.comparisonType && condition.comparisonType === 'time') {
        passing.push(this.compareTimes(serviceCallValue, condition));
      } else {
        // Compare basic values
        switch (condition.operator) {
          case '<':
            passing.push(serviceCallValue < condition.value);
            break;
          case '>':
            passing.push(serviceCallValue > condition.value);
            break;
          case '===':
            const cleansed = typeof serviceCallValue === 'string' ? serviceCallValue.trim() : serviceCallValue;
            passing.push(cleansed === condition.value);
            break;
          case '<=':
            passing.push(serviceCallValue <= condition.value);
            break;

          default:
            console.warn('Target conditional operator not supported: ', condition.operator);
            break;
        }
      }
    });

    return passing.every(b => b);
  }

  private dispatcherTODORules: DispatcherRule[] = [
    {
      name: 'New Calls 5 min old',
      // class: 'row-red',
      priority: 'Critical',
      conditions: [
        {
          property: (serviceCall: ServiceCall) => serviceCall.statuses[serviceCall.statuses.length - 1].date,
          operator: '>',
          comparisonType: 'time',
          value: '5minutes',
        },
        { property: 'currentStatus.code', operator: '===', value: '0' },
      ],
    },
    { name: 'New Calls', conditions: [{ property: 'currentStatus.code', operator: '===', value: '0' }] },
    { name: 'Pending Work Orders', conditions: [{ property: 'currentStatus.code', operator: '===', value: '-3' }] },
    { name: 'Incomplete Calls', conditions: [{ property: 'currentStatus.code', operator: '===', value: '-1' }] },
  ];

  private compareTimes(startDate: Date, condition: DispatcherRuleCondition): boolean {
    const amount = Number(condition.value.match(/\d+/g));
    const unit = condition.value.match(/[a-zA-Z]+/g);
    const timeAgo = moment().subtract(amount, unit);
    switch (condition.operator) {
      case '>':
        return moment(startDate).isBefore(timeAgo);
      case '<':
        return moment(startDate).isAfter(timeAgo);
      case '===':
        return moment(startDate).isBetween(timeAgo, moment());
      default:
        console.warn('Target conditional operator not supported: ', condition.operator);
        return false;
        break;
    }
  }

  getServiceCallByUserId(userId: any) {
    return this.http.post<any>(`${this.url}serviceCallByUserId/${userId}`, {});
  }
  getServiceCallById(id: any) {
    return this.http.get(`${this.url}service/${id}`);
  }

}
