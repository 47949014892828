import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-call-center-landing',
  templateUrl: './call-center-landing.component.html',
  styleUrls: ['./call-center-landing.component.scss'],
})
export class CallCenterLandingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
