import { Component, OnInit } from '@angular/core';
import { ImportSheetType, ImportMapping, ImportValueType, ImportData } from '../../shared/spreadsheet-import/spreadsheet-import.interface';
import { WorkOrderForm } from '../../work-orders/work-order-form.interface';
import { WorkOrderFormField } from '../../work-orders/work-order-form-field.interface';
import { WorkOrderFormsService } from '../../work-orders/work-order-forms.service';
import { WorkOrderFormFieldsService } from '../../work-orders/work-order-form-fields.service';
import { OutsideVendorInfo } from './call-center-uploads.interface';

@Component({
  selector: 'app-call-center-uploads',
  templateUrl: './call-center-uploads.component.html',
  styleUrls: ['./call-center-uploads.component.scss'],
})
export class CallCenterUploadsComponent implements OnInit {

  spreadSheetTypes: ImportSheetType[];
  postImportNotes: string;

  constructor(
    private workOrderFormsService: WorkOrderFormsService,
    private workOrderFormFieldsService: WorkOrderFormFieldsService,
  ) {}

  ngOnInit(): void {
    this.spreadSheetTypes = [
      {
        name: 'Maverick Outside Vendor Data',
        importMappings:
        [
          <ImportMapping>{
            sourceName: 'DATE',
            targetName: 'date',
            required: true,
            valueType: ImportValueType.Date,
          },
          <ImportMapping>{
            sourceName: 'STM REF#',
            targetName: 'referenceNumber',
            required: true,
            valueType: ImportValueType.Number,
          },
          <ImportMapping>{
            sourceName: 'TIME OF CALL',
            targetName: 'timeOfCall',
            required: true,
            valueType: ImportValueType.TimeString,
          },
          <ImportMapping>{
            sourceName: 'ROLL TIME',
            targetName: 'rollTime',
            required: true,
            valueType: ImportValueType.TimeString,
          },
          <ImportMapping>{
            sourceName: 'DOWNTIME',
            targetName: 'downTime',
            required: true,
            valueType: ImportValueType.TimeString,
          },
          <ImportMapping>{
            sourceName: 'WO#',
            targetName: 'workOrderNumber',
            required: true,
            valueType: ImportValueType.Number,
            unique: true,
          },
          <ImportMapping>{
            sourceName: 'DR#',
            targetName: 'deliveryReceiptNumber',
            required: false,
            valueType: ImportValueType.String,
          },
          <ImportMapping>{
            sourceName: 'OUTSIDE VENDOR NAME',
            targetName: 'outsideVendorName',
            required: false,
            valueType: ImportValueType.String,
          },
          <ImportMapping>{
            sourceName: 'Service City/State',
            targetName: 'serviceCityState',
            required: true,
            valueType: ImportValueType.String,
          },
          <ImportMapping>{
            sourceName: 'Truck #',
            targetName: 'truckNumber',
            required: false,
            valueType: ImportValueType.String,
          },
          <ImportMapping>{
            sourceName: 'Trailer #',
            targetName: 'trailerNumber',
            required: false,
            valueType: ImportValueType.String,
          },
          <ImportMapping>{
            sourceName: 'Tire Size',
            targetName: 'tireSize',
            required: false,
            valueType: ImportValueType.String,
          },
          <ImportMapping>{
            sourceName: 'Service Performed',
            targetName: 'servicePerformed',
            required: false,
            valueType: ImportValueType.String,
          },
          <ImportMapping>{
            sourceName: 'Cause of Failure Reason',
            targetName: 'causeOfFailureReason',
            required: false,
            valueType: ImportValueType.String,
          },
          <ImportMapping>{
            sourceName: 'Tire Position(s)',
            targetName: 'tirePositions',
            required: false,
            valueType: ImportValueType.String,
          },
        ],
      },
    ];
  }

  async onDataReady(event: ImportData) {
    let recordsUpdated: number = 0;
    let recordsCreated: number = 0;
    let totalRecords: number = 0;
    let currentRowNumber: number = 0;

    switch (event.importSheetType.name) {
      case 'Maverick Outside Vendor Data':

        const formName = 'CALL_CENTER_UPLOAD';

        const chunkSize: number = 40;

        const dataChunks = event.data.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / chunkSize);
          if (!resultArray[chunkIndex]) resultArray[chunkIndex] = [];
          resultArray[chunkIndex].push(item);
          return resultArray;
        }, []);

        totalRecords = event.data.filter(row => row !== undefined).length;

        const errors: string[] = [];

        this.updateNotes(errors, recordsCreated, recordsUpdated, totalRecords, currentRowNumber);

        dataChunks.forEach(async (dataChunk) => {
          // find existing docs
          const query = {
            formName,
            invoiceNumber__in:dataChunk.reduce((a, c, i) => { a.push(c.workOrderNumber); return a; }, []),
            pageSize: 1000000000,
          };

          const existingFormFieldsResult = await this.workOrderFormFieldsService.findMany(query).toPromise();

          dataChunk.forEach(async (row: OutsideVendorInfo, rowIndex: number) => {
            currentRowNumber++;

            const fields = Object.keys(row).map((key, index) => {
              return <WorkOrderFormField>{
                formName,
                key,
                invoiceNumber: row.workOrderNumber,
                value: row[key],
                formCode: key,
                sequenceNumber: index,
                isDeleted: false,
                isHistory: false,
              };
            });

            const workOrderForm = <WorkOrderForm>{
              formName,
              invoiceNumber: row.workOrderNumber,
              fields: [],
              isHistory: false,
            };

            const fieldProcess = new Promise(async (resolve, reject) => {

              const updates = fields.map((field) => {
                const existingFormField: WorkOrderFormField =
                existingFormFieldsResult.documents
                .find((el: WorkOrderFormField) =>
                (Number(el.invoiceNumber) === Number(field.invoiceNumber) && el.key.toString().trim() === field.key.toString().trim()));

                if (existingFormField) {
                  if (existingFormField.value === null || existingFormField.value.toString().trim() !== field.value.toString().trim()) {
                    const fieldType: ImportMapping = event.importSheetType.importMappings.find(map => map.targetName === field.key);

                    switch (fieldType.valueType) {
                      default:
                        return existingFormField;
                        break;
                    }
                  }
                } else {
                  return field;
                }
              }).filter(el => el !== undefined);

              const hasNewRecords: boolean = (updates.filter(update => update._id === undefined).length > 0);

              if (updates && updates.length) {
                await this.workOrderFormFieldsService.create(updates).toPromise();
              }

              this.delay(100);

              resolve({ updates, hasNewRecords, workOrderNumber: row.workOrderNumber });
            });

            fieldProcess.then(async (updateInfo: {updates: WorkOrderFormField[], workOrderNumber: number, hasNewRecords: boolean}) => {

              if (updateInfo.hasNewRecords) {
                recordsCreated++;
              } else {
                recordsUpdated++;
              }

              this.updateNotes(errors, recordsCreated, recordsUpdated, totalRecords, currentRowNumber);
            });
          });
        });
        break;
    }
  }

  updateNotes (errors: string[], recordsCreated: number, recordsUpdated: number, totalRecords: number, currentIndex: number) {

    const processedCount = recordsCreated + recordsUpdated;

    this.postImportNotes = `<p><strong>Status:</strong></p><p>${processedCount}/${totalRecords} records processed. ${recordsCreated} records created. ${recordsUpdated} records updated.</p>`;

    if (processedCount === totalRecords) {
      this.postImportNotes += '<p><strong>Processing Complete</strong></p>';
      this.postImportNotes += `<p><strong>Errors:</strong></p><p>${((errors && errors.length) ? errors.join('<br>') : 'No errors to report.')}</p>`;
    } else if (processedCount === 0) {
      this.postImportNotes = '<p><strong>Please wait. Processing starting.</strong></p>';
    } else {
      this.postImportNotes += '<p><strong>Please wait. Data is still processing.</strong></p>';
    }
  }

  delay (ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
