import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CrudBaseService } from '../shared/crud-base.service';
import { QueryParams } from '../shared/base-http.service';

@Injectable({ providedIn: 'root' })
export class WorkOrderFormsService extends CrudBaseService<any> {
  urlPath = 'work-orders/work-order-forms';

  constructor(protected http: HttpClient) {
    super();
  }
}
