import { Component, OnInit } from '@angular/core';
import { WorkQueue, WorkQueueSelectedFilter, TaskType, Task, TaskQuery } from '../../shared/work-queue/work-queue.interface';
import { TaskService } from '../../shared/work-queue/task.service';
import { WorkOrdersService } from '../../work-orders/work-orders.service';
import { CustomersService } from '../../customers/customers.service';
import { PagedResponse } from '../../shared/crud-base.service';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-call-center-work-queue',
  templateUrl: './call-center-work-queue.component.html',
  styleUrls: ['./call-center-work-queue.component.scss'],
})
export class CallCenterWorkQueueComponent implements OnInit {

  workQueue: WorkQueue;
  lastUpdated = moment();
  selectedFilters: WorkQueueSelectedFilter = {
    selectedStatusFilter:{
      label: 'Incomplete',
      value: 'incomplete',
    },
    selectedDayFilter:{
      label: '30 Days',
      value: 30,
    },
  };
  taskQuery: TaskQuery;

  constructor (private taskService: TaskService, private workOrdersService: WorkOrdersService, private customersService: CustomersService) {
    this.workQueue = <WorkQueue>{
      taskTypes: [],
    };
  }

  ngOnInit(): void {
    this.workQueue = <WorkQueue>{
      taskTypes: [<TaskType>{
        name: 'PurchaseOrderNumberUpdate',
        header: 'Update Purchase Order Numbers',
        rank: 1,
        useLocking: true,
        columns: [
        { field:'referenceNumber', header:'Reference' },
        { field:'invoiceNumber', header:'InvoiceNumber' },
        { field:'customerNumber', header:'Customer Number' },
        { field:'customerName', header:'Customer' },
        { field:'customerSuppliedPONumber', header:'Customer PO Number' },
        ],
      }]};

    this.loadTasks(this.selectedFilters);
  }

  async loadTasks(selectedFilters:WorkQueueSelectedFilter) {
    const query = <TaskQuery>{ complete: selectedFilters.selectedStatusFilter.value === 'complete' };
    if (selectedFilters.selectedStatusFilter.value.match(/-locked/g)) query.locked = true;
    if (selectedFilters.selectedStatusFilter.value.match(/-unlocked/g)) query.locked = false;

    // day fitler
    if (selectedFilters.selectedDayFilter.value !== -1) query.dateAdded__gte = moment().subtract(selectedFilters.selectedDayFilter.value, 'day').startOf('day').valueOf();

    query.pageSize = 'all';

    this.taskQuery = query;

    this.workQueue.taskTypes.forEach((taskType) => {
      taskType.isLoading = true;

      taskType.tasks = [];

      // remove subscription if exists
      if (taskType.subscription) taskType.subscription.unsubscribe();

      taskType.subscription = this.taskService
      .findManyRealtime({ ...this.taskQuery, taskTypeName: taskType.name })
      .pipe(
        map((res: PagedResponse<Task>) => {
          this.lastUpdated = moment();
          return res.documents;
        }),
      )
      .subscribe(async (tasks: Task[]) => {

        const referenceIDs = tasks.map((task) => { return task.referenceID; }).filter(el => el !== undefined);

        // get reference data
        const referenceQuery = { _id__in: referenceIDs, pageSize: 'all' };

        let taskData = [];

        switch (taskType.name){
          case 'PurchaseOrderNumberUpdate':
            try {
              if (referenceIDs.length) taskData = await this.getPurchaseOrderData(referenceQuery);
            } catch (error) {
              console.log('Error loading taskData:', error);
            }
            break;
        }

        tasks.forEach((task) => {
          task.data = [];

          if (taskData) {
            const foundData = taskData.find(doc => doc._id === task.referenceID);
            if (foundData) task.data = foundData;
          }
        });

        taskType.tasks = tasks;
        taskType.isLoading = false;
      });
    });
  }

  async getPurchaseOrderData (referenceQuery) {
    try {
      const referenceDataResults = await this.workOrdersService.findMany(referenceQuery).toPromise();
      if (referenceDataResults.documents) {
        try {
          const customerNumbers = [...new Set(referenceDataResults.documents.map((doc) => { return doc.customerNumber; }))];
          if (customerNumbers) {
            if (customerNumbers.length === 1) customerNumbers.push(0);
            const customerDataResult = await this.customersService
            .findMany({ customerNumber__in: customerNumbers, pageSize: 'all' }).toPromise();
            customerDataResult.documents.forEach((doc) => {
              referenceDataResults.documents.filter(refDoc => refDoc.customerNumber === doc.customerNumber)
              .forEach((customerRefDoc) => {
                customerRefDoc.customerName = doc.name;
              });
            });
          }
        } catch (error) {
          console.log('Error getting Customer Data:', error);
        }
      }
      return referenceDataResults.documents;
    } catch (error) {
      console.log('Error getting PO Data:', error);
    }
  }

  taskMarkedComplete (task) {
    // console.log(task);
  }
}
