import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { TopBarComponent } from './top-bar/top-bar.component';
import { LayoutComponent } from './layout/layout.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';

@NgModule({
  declarations: [TopBarComponent, LayoutComponent, BreadcrumbComponent],
  imports: [CommonModule, SharedModule],
  exports: [LayoutComponent],
})
export class LayoutModule {}
