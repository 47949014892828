<div>
    <div class="p-grid">
        <div class="p-col-1"></div>
        <div class="p-col-10">
            <h4>{{moduleName}} Work Queue</h4>
            <div class="p-grid">
                <div class="p-col-1 right">Status</div>
                <div class="p-col-2"><p-dropdown [options]="statusFilterOptions" [(ngModel)]="selectedStatusFilter" optionLabel="label" (onChange)="onFilterChange($event)"></p-dropdown></div>
                <div class="p-col-1 right">Days</div>
                <div class="p-col-2"><p-dropdown [options]="dayFilterOptions" [(ngModel)]="selectedDayFilter" optionLabel="label" (onChange)="onFilterChange($event)"></p-dropdown></div>
            </div>
            <p-accordion *ngFor="let taskType of workQueue.taskTypes; let i = index">
                <p-accordionTab>
                    <p-header>
                        <span>{{ getTaskTypeHeader(taskType) }}</span>
                    </p-header>
                    <div class='loader' *ngIf="taskType.isLoading"></div>
                    <p-table [value]="taskType.tasks" *ngIf="!taskType.isLoading">
                        <ng-template pTemplate="header">
                            <tr>
                                <th *ngFor="let col of taskType.columns">
                                    {{col.header}}
                                </th>
                                <th>Task Added</th>
                                <th *ngIf="taskType.useLocking" style="width:5%;text-align:center;"><i class="fas fa-lock"></i></th>
                                <th style="width:5%;text-align:center;"><i class="fas fa-check"></i></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-task>
                            <tr>
                                <td *ngFor="let col of taskType.columns">
                                        {{task.data[col.field]}}
                                </td>
                                <td>
                                    <span title="Added By: {{task.__meta.createdBy.name}} on {{task.__meta.createdDate | date:'full'}}">{{task.__meta.createdDate | date:'shortDate'}}</span>
                                </td>
                                <td *ngIf="taskType.useLocking">
                                    <p-checkbox [(ngModel)]="task.locked" [binary]="'true'" (onChange)="toggleLock($event,taskType,task)" [title]="getLockTitle(task)"></p-checkbox>
                                </td>
                                <td>
                                    <p-checkbox [(ngModel)]="task.complete" [binary]="'true'" [disabled]="task.locked" (onChange)="toggleComplete($event,taskType,task)" [title]="getCompleteTitle(task)"></p-checkbox>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>
</div>