import { LayoutModule } from './../layout/layout.module';
import { ServiceCallsRoutingModule } from './service-calls-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceCallSearchComponent } from './service-call-search/service-call-search.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    ServiceCallSearchComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    LayoutModule,
    ServiceCallsRoutingModule,
  ],
})
export class ServiceCallsModule { }
