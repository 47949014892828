import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanAuthenticationGuard } from './core/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: './core/core.module#CoreModule',
  },
  {
    path: 'call-center',
    loadChildren: './call-center/call-center.module#CallCenterModule',
  },
  {
    path: 'service-calls',
    loadChildren: './service-calls/service-calls.module#ServiceCallsModule',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
