import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ImportSheetType, ImportMapping, ImportValueType, ImportData } from './spreadsheet-import.interface';
import * as XLSX from 'xlsx';
import * as moment from 'moment';

interface SheetNameOption {
  label: string;
  value: string;
}

@Component({
  selector: 'app-spreadsheet-import',
  templateUrl: './spreadsheet-import.component.html',
  styleUrls: ['./spreadsheet-import.component.scss'],
})
export class SpreadsheetImportComponent implements OnInit {

  @Input() moduleName: string;
  @Input() importSheetTypes: ImportSheetType[];
  @Input() postImportNotes: string;
  @Output() dataReady = new EventEmitter<ImportData>();

  sheetTypeOptions: {label: string, value: ImportSheetType}[];
  selectedSheetType: ImportSheetType;

  workbook: XLSX.WorkBook = <XLSX.WorkBook>{};
  sheetNameOptions: SheetNameOption[];
  selectedSheetName: string;
  hasRequiredColumns: boolean;
  notes: string;
  importComplete: boolean;
  importCount: number;
  jsonData: any[][];
  returnData: any[];

  currentStep: number = 1;

  constructor() { }

  ngOnInit(): void {
    this.sheetTypeOptions = [];

    this.importSheetTypes.forEach((importSheetType) => {
      this.sheetTypeOptions.push({
        label: importSheetType.name,
        value: importSheetType,
      });
    });

    if (this.sheetTypeOptions.length) this.selectedSheetType = this.sheetTypeOptions[0].value;
  }

  async onFileChange(event) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();

      reader.onload = function (e) {
        const data = e.target.result;
        this.workbook = XLSX.read(data, { type: 'array' });

        // populate sheet options
        this.sheetNameOptions = this.workbook.SheetNames.map((sheet) => {
          return <SheetNameOption>{
            label: sheet,
            value: sheet,
          };
        });

        // default to first option (avoid undefined)
        if (this.sheetNameOptions && this.sheetNameOptions.length) {
          this.selectedSheetName = this.sheetNameOptions[0].label;
        }
      }.bind(this);

      reader.onloadend = function(e) {
        this.next();
      }.bind(this);

      reader.readAsArrayBuffer(file);
    }
  }

  createColumnMap() {
    this.jsonData = XLSX.utils.sheet_to_json(this.workbook.Sheets[this.selectedSheetName], { header: 1 });

    // first row must contain columns names
    const headerRow = this.jsonData[0];

    this.hasRequiredColumns = true;

    // update index on importMappings
    this.selectedSheetType.importMappings.forEach((importMapping) => {
      importMapping.index = headerRow.indexOf(importMapping.sourceName);
      if (importMapping.required && importMapping.index === -1) this.hasRequiredColumns = false;
    });
  }

  next() {

    this.currentStep += 1;

    // what to do before moving on?
    switch (this.currentStep) {
      case 3:
        this.createColumnMap();
        break;

      case 4:
        this.process();
        break;

      case 5:
        this.dataReady.emit(<ImportData>{ importSheetType: this.selectedSheetType, data: this.returnData });
        break;
    }

  }

  async process() {

    this.importComplete = false;
    this.returnData = [];

    this.notes = '';

    if (this.hasRequiredColumns) {
      this.importCount = 0;

      this.jsonData.forEach((row, rowIndex) => {

        if (rowIndex > 0) {
          const obj = {};
          const missingColumnList = [];
          const invalidColumnList = [];

          this.selectedSheetType.importMappings.forEach((col: ImportMapping) => {
            if (row[col.index]) {
              const cellRef = XLSX.utils.encode_cell({ c: col.index, r: rowIndex });
              const cell = this.workbook.Sheets[this.selectedSheetName][cellRef];

              switch (col.valueType){
                case ImportValueType.String:
                  if (col.required && row[col.index] === '') {
                    invalidColumnList.push(col.sourceName);
                  } else {
                    if (row[col.index] !== '') {
                      obj[col.targetName] = row[col.index];
                    }
                  }
                  break;

                case ImportValueType.Number:
                  if (!isNaN(+row[col.index])) {
                    obj[col.targetName] = row[col.index];
                  } else {
                    invalidColumnList.push(col.sourceName);
                  }
                  break;

                case ImportValueType.Time:
                  // have to get time values direct from the sheet
                  if (cell && moment(cell.w, 'HH:mm:ss')) {
                    obj[col.targetName] = moment(cell.w, 'HH:mm:ss').toDate();
                  } else {
                    invalidColumnList.push(col.sourceName);
                  }
                  break;

                // ex. 13:45 => "13:45"
                case ImportValueType.TimeString:
                  // have to get time values direct from the sheet
                  if (cell) obj[col.targetName] = cell.w;
                  break;

                case ImportValueType.Date:
                  obj[col.targetName] = moment(String(row[col.index])).toDate();
                  break;
              }
            } else {
              // only report if required field is missing
              if (col.required) missingColumnList.push(col.sourceName);
            }
          });

          // got missing columns? report them.
          if (missingColumnList.length === 0 && invalidColumnList.length === 0 && obj) {
            this.returnData[rowIndex] = obj;
            this.importCount++;
          } else {
            if (missingColumnList.length) this.notes += `Row: ${rowIndex + 1} - Missing Data: ${missingColumnList.join(',')}\n`;
            if (invalidColumnList.length) this.notes += `Row: ${rowIndex + 1} - Invalid Data: ${invalidColumnList.join(',')}\n`;
          }
        }
      });

      const uniqueColumns = this.selectedSheetType.importMappings.filter(el => el.unique);

      // check for unique fields
      uniqueColumns.forEach((uniqueColumn) => {
        const duplicates = this.returnData
        .map((c, i, a) => {
          return a.findIndex((el, elid) => (el
            && el[uniqueColumn.targetName]
            && c[uniqueColumn.targetName]
            && el[uniqueColumn.targetName] === c[uniqueColumn.targetName]
            && elid !== i));
          })
        .filter(c => c !== -1)
        .map((el) => { return this.returnData[el][uniqueColumn.targetName]; })
        .filter((c, i, a) => a.indexOf(c) === i);

        if (duplicates.length > 0) {
          this.notes += `Found duplicates for ${uniqueColumn.sourceName}: ${duplicates.join(', ')}\n`;
        }
      });

      // update notes
      this.notes = this.notes.replace(new RegExp('\n', 'g'), '<br>');
      if (this.notes.length === 0) this.notes = 'No errors to report.';

      this.importComplete = true;
    } else {

      // update notes
      this.notes += 'Could not import. Mappings missing for the following columns: ';
      this.notes += `${this.selectedSheetType.importMappings
                          .filter(el => el.required && el.index === undefined)
                          .map(el => el.sourceName)
                          .join(',')}\n`;
      this.importComplete = true;
    }

  }

  reset () {
    this.currentStep = 1;
  }
}
