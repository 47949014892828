import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Message } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class ToastService {
  private messageBehaviorSubject: BehaviorSubject<Message> = new BehaviorSubject<Message>(null);
  message: Observable<Message> = this.messageBehaviorSubject.asObservable();

  constructor() {}

  add(message: Message): void {
    this.messageBehaviorSubject.next(message);
  }
}
