import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StoreService } from '../stores/stores.service';
import { Person } from '../people/person.interface';
import { startCase, toLower } from 'lodash';
import { environment } from 'src/environments/environment';
import { Store } from '../stores/stores.interface';

@Injectable({ providedIn: 'root' })
export class UsersService {
  private loggedInUserSubject: BehaviorSubject<Person> = new BehaviorSubject<Person>(null);
  loggedInUser$: Observable<Person> = this.loggedInUserSubject.asObservable();

  constructor(private storeService: StoreService) { }

  get loggedInUser(): Person {
    return this.loggedInUserSubject.value;
  }

  async setLoggedInUser(person: Person): Promise<Person> {
    person.displayName = this.getDisplayName(person);
    person.employeeId = Number(person.contexts.keycloakProfile.attributes.employee_id);
    person.isExecutive = this.isExecutive(person);
    person.isManager = this.isManager(person);
    person.isSalesperson = this.isSalesperson(person);
    person.isTrainer = this.isTrainer(person);
    person.isInventoryCzar = this.isInventoryCzar(person);

    await this.setUserStores(person);
    this.updateLoggedInUserSelectedStore();
    this.loggedInUserSubject.next(person);
    return person;
  }

  async setUserStores(person: Person): Promise<Person> {
    const canSeeAllStores = person.isExecutive || person.isInventoryCzar;
    const userStoreNumbers = person.contexts.keycloakProfile.attributes.store_numbers;
    const storeQuery = canSeeAllStores ?
      { pageSize: 'all', storeType__in: ['RETAIL', 'WHOLESALE'], isActive: true, select: ['storeNumber', 'name', 'location'] } :
      { pageSize: 'all', storeNumber__in: userStoreNumbers, isActive: true, select: ['storeNumber', 'name', 'location'] };
    person.stores = [];
    // await this.storeService.findMany(storeQuery)
     // .pipe(map((res: PagedResponse<Store>) => res.documents)).toPromise();
    person.stores.sort((a: Store, b: Store) => {
      return a.storeNumber - b.storeNumber;
    });
    person.selectedStore = canSeeAllStores ?
      person.stores[0] :
      person.stores.find((s: Store) => s.storeNumber === Number(userStoreNumbers[0]));
    this.setSelectedStore(person.selectedStore);
    return person;
  }

  updateLoggedInUserSelectedStore(): void {
    // this.storeService.selectedStore.subscribe((store: Store) => {
    //   const user = this.loggedInUser;
    //   if (store && user) {
    //     user.selectedStore = store;
    //     // TODO: Save users selected store in db.
    //     this.loggedInUserSubject.next(user);
    //   }
    // });
  }

  getDisplayName(person: Person): string {
    let displayName;
    const { keycloakProfile, greatPlainsEmployee } = person.contexts;
    if (greatPlainsEmployee && greatPlainsEmployee.lastName) {
      if (greatPlainsEmployee.preferredName) {
        displayName = `${greatPlainsEmployee.preferredName} ${greatPlainsEmployee.lastName}`;
      } else {
        displayName = `${greatPlainsEmployee.firstName} ${greatPlainsEmployee.lastName}`;
      }
    } else {
      displayName = keycloakProfile.displayName;
    }

    displayName = startCase(toLower(displayName)); // JIMMY ARROYO -> Jimmy Arroyo
    if (!environment.production) {
      displayName = `DEV ${displayName}`;
    }
    return displayName;
  }

  isExecutive(person: Person): boolean {
    return person.contexts.keycloakProfile.attributes.roles.includes('executive');
  }

  isManager(person: Person): boolean {
    return person.managedStoreNumbers && person.managedStoreNumbers.length > 0;
  }

  isSalesperson(person: Person): boolean {
    return !this.isManager(person)
      && !this.isExecutive(person)
      && Boolean(person.contexts.maddenCoSalesperson);
  }

  isTrainer(person: Person): boolean {
    return person.contexts.greatPlainsEmployee
      && person.contexts.greatPlainsEmployee.divisionKey === 'CORTRA';
  }

  isInventoryCzar(person: Person): boolean {
    return person.contexts.keycloakProfile &&
      person.contexts.keycloakProfile.attributes &&
      person.contexts.keycloakProfile.attributes.roles &&
      person.contexts.keycloakProfile.attributes.roles.indexOf('INVENTORY_CZAR') !== -1;
  }

  setSelectedStore(store: Store): void {
    // this.storeService.setSelectedStore(null, store);
  }

}
