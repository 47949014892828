import { keycloakProd } from './keycloak.env';

const BACKEND_BASE_URL = 'https://api.customers.dev.stmtires.com';
export const environment = {
  production: true,
  keycloak: keycloakProd,
  backendBaseURL: BACKEND_BASE_URL,
  apiURL: `${BACKEND_BASE_URL}/api/v1`,
  googleAnalyticsTag: '',
};
