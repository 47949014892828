import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare let gtag: Function; // Declare ga as a function

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsService {

  constructor() { }

  // Create our event emitter to send our data to Google Analytics
  public eventEmitter(eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: number = null): void {
    if (environment.googleAnalyticsTag) {
      gtag('event', eventLabel, { eventCategory, eventLabel, eventAction, eventValue });
    }
  }
}
