<div class="stm-header-container">
  <div class="stm-header p-grid">
    <div class="p-col p-d-flex p-jc-start p-ai-center stm-header-group-left">
      <img src="assets/img/STMLogo_HOR._RGB.svg" routerLink="/" class="logo p-mr-2">
    </div>
    <div class="p-col p-d-flex p-jc-center p-ai-center stm-header-group-center">
      <img [src]="customerLogo" style="height:3rem;" class="customer-logo p-mr-2">
      <span style="color: #444;font-weight:bolder;">CUSTOMER PORTAL</span>
    </div>
    <div class="p-col p-d-flex p-jc-end p-ai-center stm-header-group-right">
      <!-- <input type="text" pInputText placeholder="Search"> -->
      <button type="button" class="p-button-raised p-button-danger" pButton pRipple label="Request Service"></button>
      <p-menu styleClass="user-menu" #uMenu [popup]="true" [model]="userMenu.menuItems"></p-menu>
      <button type="button" class="p-button-text" pButton icon="pi pi-angle-down" iconPos="right" label="{{userMenu.displayName}}" (click)="uMenu.toggle($event)"></button>
    </div>
  </div>
  <div class="stm-nav p-grid p-d-flex p-jc-center p-ai-center">
    <span routerLink="/">Home</span>
    <span>Daily Report</span>
    <span routerLink="/service-calls/search">Service History</span>
    <span>Contacts</span>
  </div>
</div>
