import { KeycloakProfile } from 'keycloak-js';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { LayoutService } from '../layout/layout.service';
import { Dialog } from '../layout/layout.interface';
import { KeycloakUser } from './auth.interface';
import { UsersService } from './users.service';
import { PeopleService } from '../people/people.service';
import { Person } from '../people/person.interface';

@Injectable({ providedIn: 'root' })
export class AuthService {
  keycloakTokenInvalid: boolean = false;
  watchToken: boolean = false;

  constructor(
    private keycloakService: KeycloakService,
    private layoutService: LayoutService,
    private peopleService: PeopleService,
    private userService: UsersService,
  ) {
    this.watchTokenValidity();
  }

  async getUser(): Promise<Person> {
    const keycloakProfile = await this.keycloakService.loadUserProfile() as KeycloakUser;

    const employeeId = Number(keycloakProfile.attributes.employee_id);
    keycloakProfile.displayName = `${keycloakProfile.firstName} ${keycloakProfile.lastName}`;

    // const person = await this.peopleService.getByEmployeeNumber(employeeId).toPromise();
    const person = {
      displayName: keycloakProfile.displayName,
      contexts: {
        keycloakProfile,
      },
    };

    person.contexts.keycloakProfile = keycloakProfile;
    return await this.userService.setLoggedInUser(person);
  }

  private watchTokenValidity(): void {
    this.watchToken = true;
    const twoMinutes = 120000;
    interval(twoMinutes)
      .pipe(takeWhile(() => !this.keycloakTokenInvalid && this.watchToken))
      .subscribe(() => {
        this.checkTokenStatus();
      });
  }

  async checkTokenStatus(): Promise<void> {
    try {
      const keycloak = this.keycloakService.getKeycloakInstance();

      // updateToken checks to see if the keycloak session is valid in keycloak;
      keycloak.updateToken(5000).success((tokenUpdated: boolean) => {

      }).error((error) => {
        console.error(error);
        console.log('********************');
        console.log('ERROR UPDATING TOKEN');
        console.log('********************');
        this.showLoggedOutMessage();
        this.keycloakTokenInvalid = true;
      });
    } catch (error) {
      console.error(error);
      this.keycloakTokenInvalid = true;
    }
  }

  showLoggedOutMessage(): void {
    const dialog: Dialog = {
      visible: true,
      header: 'Logged Out',
      buttonText: 'Go To Login',
      buttonIcon: 'fas fa-user-lock',
      body:
        'Your session has expired or you have been logged out from another location. You will be redirected to log in.',
      func: () => {
        this.keycloakService.login();
      },
    };
    this.layoutService.updateDialog(dialog);
  }

  showMissingStoresMessage(): void {
    console.log('user has no stores');

    const dialog: Dialog = {
      visible: true,
      header: 'Missing Information',
      buttonText: 'Reload',
      buttonIcon: 'fas fa-sync-alt',
      body: "Your account doesn't have any stores assigned to it. Please contact support.",
      func: () => {
        location.reload();
      },
    };
    this.layoutService.updateDialog(dialog);
  }

  showMissingEmployeeNumberMessage(): void {
    console.log('user has no employee_id');

    const dialog: Dialog = {
      visible: true,
      header: 'Missing Information',
      buttonText: 'Reload',
      buttonIcon: 'fas fa-sync-alt',
      body: "Your account doesn't have an employee number assigned to it. Please contact support.",
      func: () => {
        location.reload();
      },
    };
    this.layoutService.updateDialog(dialog);
  }
}
