import { Component } from '@angular/core';
import { LayoutService } from './layout/layout.service';
import { Layout } from './layout/layout.interface';
import { KeycloakService } from 'keycloak-angular';
import { UsersService } from './core/users.service';
import { Person } from './people/person.interface';
import { Router, NavigationEnd, RouterEvent } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GoogleAnalyticsService } from './core/google-analytics.service';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'customer-portal';
  user: Person;
  dialog: any = { visible: false };
  constructor(
    private keycloakService: KeycloakService,
    private layoutService: LayoutService,
    private userService: UsersService, // Don't remove this, it loads the token auto watcher;
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router,
  ) {
    this.dialogWatcher();
  }

  async ngOnInit() {
    if (await this.keycloakService.isLoggedIn()) {
      this.userService.loggedInUser$.subscribe((person: Person) => {
        if (person) {
          this.user = person;
          this.googleAnalyticsService.eventEmitter('App', 'Login', person.displayName);
        }
        this.initLayout();
      });
    }
  }

  initLayout(): void {
    const layout: Layout = {
      topbar: {
        menuItems: [],
        userMenu: {},
      },
    };
    if (this.user) {
      layout.topbar.userMenu = {
        displayName: this.user.displayName,
        menuItems: [
          {
            label: '',
            items: [
              {
                label: 'Logout',
                command: () => this.keycloakService.logout(),
                icon: 'fad fa-sign-out-alt',
              },
            ],
          },
        ],
      };
    }
    this.layoutService.init(layout).subscribe();
  }

  dialogWatcher() {
    this.layoutService.layout.subscribe((layout: Layout) => {
      this.dialog = layout.dialog || { visible: false };
    });
  }
}
