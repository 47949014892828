import { Component, OnInit } from '@angular/core';
import { ServiceCall } from '../service-call.interface';
import { ServiceCallsService } from '../service-calls.service';
import { WorkOrdersService } from '../../work-orders/work-orders.service';
import { TaskService } from '../../shared/work-queue/task.service';
import { Task } from '../../shared/work-queue/work-queue.interface';
import { Store } from 'src/app/stores/stores.interface';
import { StoreService } from 'src/app/stores/stores.service';
import { AuthService } from 'src/app/core/auth.service';
import { PagedResponse } from 'src/app/shared/crud-base.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { LazyLoadEvent } from 'primeng/api';

interface SearchServiceCall {
  workOrderID?: string;
  customerSuppliedPONumber?: string;
  callStartedDate?: string;
  callStartedTime?: string;
  callCompletedDate?: string;
  rollTime?: string;
  referenceNumber: number;
  downTime?: string;
  workOrderNumber?: string;
  deliveryReceiptNumber?: string;
  outsideVendorName?: string;
  cityState?: string;
  tireSize?: string;
  servicePerformed?: string;
  driverName?: string;
  truckNumber?: string;
  trailerNumber?: string;
  causeOfFailure?: string;
  tirePositions?: string;
}
@Component({
  selector: 'app-service-call-search',
  templateUrl: './service-call-search.component.html',
  styleUrls: ['./service-call-search.component.scss'],
})
export class ServiceCallSearchComponent implements OnInit {
  serviceCalls: ServiceCall[];
  store: Store;
  searchParams: any = {};
  pageSize: number = 15;
  pageNumber: number = 1;
  first: number = 0;
  totalServiceCallCount: number = 0;
  tableData: SearchServiceCall[] = [];
  statuses: any[] = [];
  searched: boolean = false;
  searching: boolean = false;
  exportFilename: string = `service_calls_${moment().format('MM-DD-YYYY_hhmmssA')}`;

  editRecord: any;

  // TODO: make these configurable based on customer preferences
  cols: any[] = [
    { field: 'callStartedDate', header: 'Date' },
    { field: 'referenceNumber', header: 'STM Ref #' },
    { field: 'callStartedTime', header: 'Time of Call' },
    { field: 'rollTime', header: 'Roll Time' },
    { field: 'downTime', header: 'Down Time' },
    { field: 'workOrderNumber', header: 'WO#' },
    { field: 'deliveryReceiptNumber', header: 'DR#' },
    { field: 'outsideVendorName', header: 'Outside Vendor Name' },
    { field: 'cityState', header: 'Service City/State' },
    { field: 'tireSize', header: 'Tire Size' },
    { field: 'servicePerformed', header: 'Service Performed' },
    { field: 'truckNumber', header: 'Truck #' },
    { field: 'trailerNumber', header: 'Trailer #' },
    { field: 'causeOfFailure', header: 'Cause of Failure' },
    { field: 'tirePositions', header: 'Wheel Position(s)' },
  ];

  constructor(
    private taskService: TaskService,
    private workOrdersService: WorkOrdersService,
    private serviceCallService: ServiceCallsService,
    private storeService: StoreService,
    private authService: AuthService,
    private router: Router,
  ) { }

  async ngOnInit() {
    this.getServiceCalls();
    this.getStatuses();
    this.serviceCallService.serviceCallSearch.subscribe((serviceCalls: ServiceCall[]) => {
      this.serviceCalls = serviceCalls;
      if (serviceCalls) {
        this.prepareTableData();
      }
    });
  }

  getServiceCalls(goBackToFirstPage = false): void {
    this.searching = true;
    // this.tableData = [];
    const params = this.removeEmptyQueryParams();
    this.modifyCustomerQueryParam(params);
    this.modifyCreatedDateQueryParam(params);
    this.modifyTechQueryParam(params);
    if (goBackToFirstPage) {
      this.pageNumber = 1;
      this.first = 1;
    }
    params.pageSize = this.pageSize;
    params.pageNumber = this.pageNumber;
    params.isVoid = false;

    this.serviceCallService.findMany(params)
      .subscribe((response: PagedResponse<ServiceCall>) => {
        this.totalServiceCallCount = response.totalCount;
        this.serviceCallService.serviceCallSearchBehaviorSubject.next(response.documents);
      });
  }

  search(): void {
    this.getServiceCalls(true);
  }

  modifyCustomerQueryParam(params: any): void {
    // TODO: get customer info from logged in user
    // if (params.customer) {
    //   const val = params.customer;
    //   const isNumber = !isNaN(Number(val));
    //   if (isNumber) {
    //     params.customerNumber = params.customer;
    //   } else {
    //     params.customerName__contains = params.customer;
    //   }
    //   delete params.customer;
    // }
    params.customerNumber__in = [
      752203, 752204, 466578, 466579, 466580,
      466581, 466582, 466583, 466584, 751970,
      752199, 752200, 752201, 752202, 6078348,
      6078349, 6078620, 6078621, 6078622, 6078712,
      6078719, 321828, 321829, 6091106, 430071,
      6097725, 4990090, 237259, 238599, 1125947,
      1125948, 1125949, 1125950, 1125951, 1136930,
      290859, 6076426, 310570, 26160, 67624,
      118130, 120326, 126180, 205951, 222079,
      222080, 222081, 222082, 222083, 222807
    ];
    params.sort = '-__meta.createdDate';
  }

  modifyTechQueryParam(params: any): void {
    if (params.tech) {
      const val = params.tech;
      const isNumber = !isNaN(Number(val));
      if (isNumber) {
        params.techNumber = params.tech;
      } else {
        params.techName__contains = params.tech;
      }
      delete params.tech;
    }
  }

  modifyCreatedDateQueryParam(params: any): void {
    if (params.callStartedDate) {
      const fromMoment = moment(params.callStartedDate[0]).startOf('day');
      const toMoment = moment(params.callStartedDate[1] || params.callStartedDate[0]).endOf('day');
      const fromTimestamp = fromMoment.isValid() ? fromMoment.toDate().getTime() : null;
      const toTimestamp = toMoment.isValid() ? toMoment.toDate().getTime() : null;

      if (fromTimestamp && toTimestamp) {
        params.callStartedDate__gte = fromTimestamp;
        params.callStartedDate__lte = toTimestamp;
        delete params.callStartedDate;
      }
    }
  }

  removeEmptyQueryParams(): any {
    const params = { ...this.searchParams };
    for (const key in params) {
      if (!params[key]) {
        delete params[key];
      }
    }
    return params;
  }

  prepareTableData(): void {
    const data: SearchServiceCall[] = [];
    this.serviceCalls.forEach((serviceCall: ServiceCall) => {
      const workOrders = serviceCall.workOrders || [];
      workOrders.forEach((wo: any) => {
        const invoiceNumStr = wo.invoiceNumber.toString();
        if (invoiceNumStr.length > 3 && invoiceNumStr.indexOf('100') === 0) {
          wo.isCallCenter = true;
        }
      });
      const callStartedDate = serviceCall.callStartedDate && moment(serviceCall.callStartedDate);
      const callCompletedDate = serviceCall.callCompletedDate && moment(serviceCall.callCompletedDate);
      const serviceWorkOrder = workOrders && workOrders.length ?
        workOrders.find((wo: any) => !wo.isCallCenter) :
        null;
      data.push({
        workOrderID: serviceWorkOrder && serviceWorkOrder._id,
        customerSuppliedPONumber: serviceWorkOrder && serviceWorkOrder.customerSuppliedPONumber,
        workOrderNumber: serviceWorkOrder && serviceWorkOrder.invoiceNumber,
        referenceNumber: serviceCall.referenceNumber,
        deliveryReceiptNumber: serviceWorkOrder && serviceWorkOrder.deliveryReceiptNumber,
        callStartedDate: callStartedDate && callStartedDate.isValid() ? callStartedDate.format('M-D-YYYY') : '',
        callStartedTime: callStartedDate && callStartedDate.isValid() ? callStartedDate.format('H:mm') : '',
        callCompletedDate: callCompletedDate && callCompletedDate.isValid() ? callCompletedDate.format('M-D-YYYY') : '',
        outsideVendorName: serviceCall.form && serviceCall.form['Vendor name if not STM'] ?
          serviceCall.form['Vendor name if not STM'].replace('GIVE PO@STMTIRES.COM TO GET PO', '').replace(/,/g, ', ') :
          '',
        cityState: serviceCall.form && serviceCall.form['Service City/State'],
        tireSize: serviceCall.form && serviceCall.form['Tire Size'],
        driverName: serviceCall.form && serviceCall.form['Driver Name'],
        truckNumber: serviceCall.form && serviceCall.form['Truck #'],
        trailerNumber: serviceCall.form && serviceCall.form['Trailer #'],
        tirePositions: serviceCall.form && serviceCall.form['Tire Position(s)'],
        rollTime: serviceCall.form && serviceCall.form['Roll Time'],
        downTime: serviceCall.form &&
          (!serviceCall.form['Down Time'] ?
          this.getDownTime(callStartedDate, serviceCall.form['Roll Time']) : serviceCall.form['Down Time']),
        servicePerformed: serviceCall.form &&
          ([serviceCall.form['Service Performed'], serviceCall.form['Service Performed (cont.)']]
          .filter(el => el !== undefined).join(', ')),
        causeOfFailure: serviceCall.form && serviceCall.form['Cause of Failure Reason'],
      });
    });

    this.tableData = [...data];
    this.searching = false;
    this.searched = true;
  }

  getDownTime(callTime, rollTimeString: string):string {
    let returnString = '';

    if (callTime && rollTimeString) {
      const rollTime = moment(`${callTime.format('YYYY-MM-DD')} ${rollTimeString.replace(/.*(\d\d).?(\d\d).*/, '$1:$2')}`);

      if (callTime.isValid() && rollTime.isValid()) {
        const downTimeInMinutes: number = rollTime.diff(callTime, 'minutes');
        returnString =
        `${Math.floor(downTimeInMinutes / 60).toString().padStart(2, '0')}:${(downTimeInMinutes % 60).toString().padStart(2, '0')}`;
      }
    }

    return returnString;
  }

  navigateToServiceCall(event: any): void {
    const row = event.data;
    this.serviceCallService.setSelectedServiceCall(row.serviceCall);
    this.router.navigate(['/service-calls/', row._id]);
  }

  getStatuses(): void {
    this.statuses = [
    { value: '', label: 'All Statuses' },
    { value: '-3', label: 'WORK ORDER' },
    { value: '-2', label: 'CANCELLED' },
    { value: '-1', label: 'INCOMPLETE' },
    { value: '0', label: 'NEW CALL' },
    { value: '1', label: 'ASSIGNTECH' },
    { value: '2', label: 'DISPATCHED' },
    { value: '3', label: 'CHECK ON' },
    { value: '4', label: 'ON SITE' },
    { value: '5', label: 'COMPLETE' },
    { value: '9', label: 'FINAL' },
    { value: 'H', label: 'HISTORY' },
    { value: 'I', label: 'INVOICED' },
    { value: 'S', label: 'SENT TO STORE' },
    { value: 'V', label: 'VOID' },
    { value: 'A', label: 'ALERTS' },
    { value: 'R', label: 'RESET TIMER' },
    ];
  }

  csDataEditStarted(event) {
    this.editRecord = { ...event.data };
  }

  async csDataEditChanged(event) {
    switch (event.field) {
      case 'customerSuppliedPONumber':
        if (this.editRecord.customerSuppliedPONumber !== event.data.customerSuppliedPONumber) {
          const updateObj = {
            _id: event.data.workOrderID,
            customerSuppliedPONumber: event.data.customerSuppliedPONumber,
          };

          if (updateObj.customerSuppliedPONumber ||
            (this.editRecord &&  this.editRecord.customerSuppliedPONumber !== '')) {
            if (updateObj._id) {
              try {
                await this.workOrdersService.update(updateObj).toPromise();
              } catch (error) {
                console.log('Error Updating WorkOrder:', error);
              }

              const task: Task = <Task>{
                moduleName: 'Call Center',
                taskTypeName: 'PurchaseOrderNumberUpdate',
                referenceID: updateObj._id,
                complete: false,
                dateAdded: new Date(),
              };

              // add a new task
              try {
                await this.taskService.create(task).toPromise();
              } catch (error) {
                console.log('Error Creating Task:', error);
              }
            } else {
              alert('Could not update Purchase Order Number: Missing Work Order Number.');
            }
          } else {
            alert('Could not update Purchase Order Number: Missing Purchase Order Number.');
          }
        }
        break;
    }
  }

  paginate(event: LazyLoadEvent) {
    this.pageSize = event.rows;
    this.pageNumber = event.first / event.rows + 1;
    this.first = event.first;
    this.getServiceCalls();
  }
}
