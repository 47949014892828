import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudBaseService } from '../shared/crud-base.service';
import { QueryParams } from '../shared/base-http.service';

@Injectable({
  providedIn: 'root',
})
export class CustomersService extends CrudBaseService<any> {
  urlPath = 'customers';

  constructor(protected http: HttpClient) {
    super();
  }

  getAccountsReceivableTotals(params?: QueryParams) {
    return this.http.get<any>(`${this.url}accounts-receivable-aging`, { params: this.getHttpParams(params) });
  }

}
