<div>
    <div class="p-grid">
      <div class="p-col-1"></div>
      <div class="p-col-10">
        <br>
        <h4>{{moduleName}} Imports</h4>
        <p-accordion>
          <p-accordionTab header="Step 1: Choose Sheet Type" [selected]="currentStep == 1" [disabled]="currentStep != 1">
            <br>
            <div class="p-grid">
                <div class="p-col-2">
                    Import Type
                </div>
                <div class="p-col-2">
                    <p-dropdown inputId="selSheetType" [options]="sheetTypeOptions" [(ngModel)]="selectedSheetType"></p-dropdown>
                </div>
                <div class="p-col-1"></div>
                <div class="p-col-2">
                    <p-button label="Next" (onClick)="next()"></p-button>
                </div>
            </div>
        </p-accordionTab>
          <p-accordionTab header="Step 2: Upload File" [selected]="currentStep == 2" [disabled]="currentStep != 2">
            <input 
                id="file" 
                type="file" 
                class="form-control"
                (change)="onFileChange($event)">
          </p-accordionTab>
          <p-accordionTab header="Step 3: Choose Sheet" [selected]="currentStep == 3" [disabled]="currentStep != 3">
            <br>
            <div class="p-grid">
              <div class="p-col-2">
                Sheet Name
              </div>
              <div class="p-col-2">
                  <p-dropdown inputId="selSheetName" [options]="sheetNameOptions" [(ngModel)]="selectedSheetName" (onChange)="createColumnMap()"></p-dropdown>
              </div>
              <div class="p-col-1"></div>
              <div class="p-col-2">
                <p-button label="Review" (onClick)="next()"></p-button>
              </div>
            </div>
          </p-accordionTab>
          <p-accordionTab header="Step 4: Review" [selected]="currentStep == 4" [disabled]="currentStep != 4">
            <div *ngIf="importComplete">
                <p>{{importCount}} rows found.<br></p>
                <div *ngIf="importComplete" [innerHTML]="notes"></div>
            </div>
            <div class="p-col-2">
              <p-button label="Process" (onClick)="next()"></p-button>
            </div>
            <img id="loading" *ngIf="!importComplete" src="../../../assets/img/STMLoadingIcon.svg">
          </p-accordionTab>
          <p-accordionTab header="Step 5: Process" [selected]="currentStep == 5" [disabled]="currentStep != 5">
            <div>
                <div *ngIf="importComplete" [innerHTML]="postImportNotes"></div>
            </div>
          </p-accordionTab>
        </p-accordion>
        <div class="right"><p-button label="Reset" (onClick)="reset()"></p-button></div>
      </div>
      <div class="p-col-1"></div>
    </div>
  </div>
  <div style="height:500px;">&nbsp;</div>