import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { LayoutModule } from '../layout/layout.module';
import { CallCenterRoutingModule } from './call-center-routing.module';
import { CallCenterUploadsComponent } from './call-center-uploads/call-center-uploads.component';
import { CallCenterWorkQueueComponent } from './call-center-work-queue/call-center-work-queue.component';
import { CallCenterLandingComponent } from './call-center-landing/call-center-landing.component';

@NgModule({
  declarations: [CallCenterUploadsComponent, CallCenterWorkQueueComponent, CallCenterLandingComponent],
  imports: [
    CommonModule,
    SharedModule,
    LayoutModule,
    CallCenterRoutingModule,
  ],
})
export class CallCenterModule { }
