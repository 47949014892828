import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { map, mergeMap, startWith, debounceTime } from 'rxjs/operators';
import { SocketService } from './socket.service';
import { QueryParams } from './base-http.service';

export interface PagedResponse<T> {
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
  documents: T[];
}
export interface CRUDObject {
  _id?: string;
}

export abstract class CrudBaseService<T extends CRUDObject> {
  protected abstract urlPath: string;
  protected abstract http: HttpClient;
  protected cache: boolean = false;
  protected useSockets: boolean = true;
  private socket: SocketService;
  private cachedResults: { [url: string]: Observable<T> } = {};

  constructor(protected socketNamespaceName = '') {
    if (this.socketNamespaceName) {
      this.socket = new SocketService(this.socketNamespaceName);
    }
  }
  findMany(params?: QueryParams): Observable<PagedResponse<T>> {
    return this._http.get<PagedResponse<T>>(this.url, { params: this.getHttpParams(params) });
  }

  findManyRealtime(params?: QueryParams): Observable<PagedResponse<T>> {
    const findMany = this.findMany(params);
    return this.socket.onEvent().pipe(
      startWith(() => findMany),
      mergeMap(() => findMany),
    );
  }

  findOne(id: string, params?: QueryParams): Observable<T> {
    const url = `${this.url}${id}`;
    if (this.cache) {
      if (this.cachedResults[<string>url]) {
        return this.cachedResults[<string>url];
      }
    }

    return this._http.get<T>(url, { params: this.getHttpParams(params) }).pipe(
      map((result: T) => {
        if (this.cache) {
          this.cachedResults[<string>url] = of(result);
        }
        return result;
      }),
    );
  }

  // TODO: check to make sure socket message has to do with the same record.
  findOneRealtime(id: string, params?: QueryParams): Observable<T> {
    const findOne = this.findOne(id, params);
    return this.socket.onEvent().pipe(
      startWith(() => findOne),
      mergeMap(() => findOne),
      debounceTime(5000),
    );
  }

  create(newObject: T, params?: QueryParams): Observable<T> {
    return this._http.post<T>(this.url, newObject, { params: this.getHttpParams(params) });
  }

  update(objectToUpdate: T, params?: QueryParams): Observable<T> {
    const url = `${this.url}${objectToUpdate._id}`;
    return this._http.put<T>(url, objectToUpdate, { params: this.getHttpParams(params) });
  }

  delete(objectToDelete: T, params?: QueryParams): Observable<T> {
    const url = `${this.url}${objectToDelete._id}`;
    return this._http.delete<T>(url, { params: this.getHttpParams(params) });
  }

  protected get url(): string {
    return `${environment.apiURL}/${this.urlPath}/`;
  }

  /**
   * This is necessary because you can't directly inject an angular service (like HttpClient) into a base class
   * So it must be passed into derived constructor.
   * If it isn't, angular throws a really unhelpful error message.
   * This is here just to give a better error message that can be easily debugged.
   */
  private get _http(): HttpClient {
    if (!this.http) {
      throw new Error(
        `No HttpClient found in ${this.constructor.name}.
        You must inject 'http: HttpClient' in your service's constructor.
        Example:
          @Injectable({
            providedIn: 'root',
          })
          export class MySweetService extends CrudBaseService<SweetThang> {
            protected urlPath = '/sweet-thangs';

            constructor(protected http: HttpClient) {
              super();
            }
          }
        `,
      );
    }
    return this.http;
  }

  protected getHttpParams(params?: QueryParams): HttpParams {
    if (params) {
      let query: HttpParams = new HttpParams();
      for (const key in params) {
        if (params.hasOwnProperty(key) && params[key] !== null && params[key] !== undefined) {
          if (Array.isArray(params[key])) {
            // appends arrays with same key multiple times. Ex. ?id=1&id=2&id=3
            params[key].forEach((val: any) => {
              query = query.append(key, val.toString());
            });
          } else {
            // appends single key to query params
            query = query.append(key, params[key].toString());
          }
        }
      }
      return query;
    }
    return undefined;
  }

  // private get _socket(): SocketService {
  //   if (!this.socket) {
  //     throw new Error(
  //       `${this.constructor.name} is missing socket configuration.
  //       You must define 'useSockets = true' and set the socket namespace as needed.
  //       Example:
  //         export class MySweetService extends CrudBaseService<SweetThang> {
  //           protected urlPath = '/sweet-thangs';
  //           useSockets = true;
  //           socketNamespace = 'sweet-thangs'

  //           constructor(protected http: HttpClient) {
  //             super();
  //           }
  //         }
  //       `,
  //     );
  //   }
  //   return this._socket;
  // }
}
