import { ServiceCallsModule } from './service-calls/service-calls.module';
import { CallCenterModule } from './call-center/call-center.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, DoBootstrap, ApplicationRef } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HttpClientModule } from '@angular/common/http';
import { KeycloakAngularModule, KeycloakService, KeycloakOptions } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { SharedModule } from './shared/shared.module';

const keycloakService = new KeycloakService();

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    ServiceCallsModule,
    CallCenterModule,
    KeycloakAngularModule,
  ],
  providers: [{
    provide: KeycloakService,
    useValue: keycloakService,
  }],
  entryComponents: [AppComponent],
})
export class AppModule implements DoBootstrap {
  async ngDoBootstrap(app) {
    try {
      await keycloakService.init({
        config: environment.keycloak,
        enableBearerInterceptor: true,
        initOptions: {
          checkLoginIframe: false,
        },
      });
      app.bootstrap(AppComponent);
    } catch (error) {
      console.error('Keycloak init failed', error);
    }
  }
}
