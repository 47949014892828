import { Component } from '@angular/core';
import { LayoutService } from '../layout.service';
import { Layout } from '../layout.interface';
import { Message, MessageService } from 'primeng/api';
import { ToastService } from '../toast.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  layout: Layout;

  constructor(
    layoutService: LayoutService,
    private messageService: MessageService,
    private toastService: ToastService,
  ) {
    layoutService.layout.subscribe((layout: Layout) => {
      this.layout = layout;
    });

    this.listenForToasts();
  }

  listenForToasts() {
    this.toastService.message.subscribe((message: Message) => {
      this.messageService.add(message);
    });
  }
}
