<div class="home-container">
  <div class="daily-digest-container">
    <div class="rounded-box p-d-flex p-flex-column p-jc-start">
      <div class="daily-digest-header p-d-flex p-ai-center">DAILY DIGEST</div>
      <p-table [value]="digestItems" styleClass="p-datatable-striped daily-digest-table">
        <ng-template pTemplate="body" let-item>
          <tr>
            <td>{{item.dateTime | date:'M.dd.yy h:mm aa'}} - {{item.message}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div class="nav-buttons-container p-d-flex p-jc-between">
    <div *ngFor="let nav of navButtons" [routerLink]="nav.link" class="nav-button rounded-box p-d-flex p-flex-column p-jc-center p-ai-center p-shadow-10">
      <div class="nav-button-top p-d-flex p-jc-center p-ai-center">
        <img [src]="nav.image" [ngStyle]="{'margin-top': nav.marginTop}"/>
      </div>
      <div class="nav-button-middle p-d-flex p-jc-center p-ai-center">{{nav.title}}</div>
      <div class="nav-button-bottom p-d-flex p-jc-center p-ai-start">{{nav.description}}</div>
    </div>
  </div>
</div>

