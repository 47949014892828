import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Task } from './work-queue.interface';
import { Observable, of } from 'rxjs';
import { CrudBaseService, PagedResponse } from '../../shared/crud-base.service';
import { QueryParams } from '../../shared/base-http.service';

@Injectable({ providedIn: 'root' })
export class TaskService extends CrudBaseService<Task> {
  protected urlPath: string = 'work-queue/task';

  constructor(protected http: HttpClient) {
    super('tasks');
  }
}
