import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { PanelModule } from 'primeng/panel';
import { TimeagoModule } from 'ngx-timeago';
import { TabViewModule } from 'primeng/tabview';
import { TabMenuModule } from 'primeng/tabmenu';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AccordionModule } from 'primeng/accordion';
import { CardModule } from 'primeng/card';
import { FieldsetModule } from 'primeng/fieldset';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DialogModule } from 'primeng/dialog';
import { MessageModule } from 'primeng/message';
import { GMapModule } from 'primeng/gmap';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToolbarModule } from 'primeng/toolbar';
import { LightboxModule } from 'primeng/lightbox';
import { CalendarModule } from 'primeng/calendar';
import { SidebarModule } from 'primeng/sidebar';
import { ChartModule } from 'primeng/chart';

import { TextBadgeComponent } from './text-badge/text-badge.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SuperInputComponent } from './super-input/super-input.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MessageService, ConfirmationService } from 'primeng/api';
import { SpreadsheetImportComponent } from './spreadsheet-import/spreadsheet-import.component';
import { WorkQueueComponent } from './work-queue/work-queue.component';

export let options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TimeagoModule.forRoot(),
    NgxMaskModule.forRoot(options),
    NgxSpinnerModule,
    /* PrimeNG Imports */
    MenubarModule,
    InputTextModule,
    ButtonModule,
    MenuModule,
    TableModule,
    PanelModule,
    TabViewModule,
    GMapModule,
    TabMenuModule,
    CheckboxModule,
    RadioButtonModule,
    AccordionModule,
    CardModule,
    FieldsetModule,
    BreadcrumbModule,
    DialogModule,
    MessageModule,
    SelectButtonModule,
    InputMaskModule,
    InputSwitchModule,
    TooltipModule,
    AutoCompleteModule,
    ToastModule,
    MessagesModule,
    DropdownModule,
    EditorModule,
    ConfirmDialogModule,
    ToolbarModule,
    LightboxModule,
    CalendarModule,
    SidebarModule,
    ChartModule,
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    TextBadgeComponent,
    SuperInputComponent,
    TimeagoModule,
    NgxMaskModule,
    NgxSpinnerModule,
    /* PrimeNG Exports */
    MenubarModule,
    InputTextModule,
    ButtonModule,
    MenuModule,
    TableModule,
    PanelModule,
    TabViewModule,
    GMapModule,
    TabMenuModule,
    CheckboxModule,
    RadioButtonModule,
    AccordionModule,
    CardModule,
    FieldsetModule,
    BreadcrumbModule,
    DialogModule,
    MessageModule,
    SelectButtonModule,
    InputMaskModule,
    InputSwitchModule,
    TooltipModule,
    AutoCompleteModule,
    ToastModule,
    MessagesModule,
    DropdownModule,
    EditorModule,
    ConfirmDialogModule,
    ToolbarModule,
    LightboxModule,
    CalendarModule,
    SidebarModule,
    ChartModule,
    SpreadsheetImportComponent,
    WorkQueueComponent,
  ],
  providers: [MessageService, ConfirmationService],
  declarations: [TextBadgeComponent, SuperInputComponent, SpreadsheetImportComponent, WorkQueueComponent],
})
export class SharedModule { }
