<!-- TODO: Put appropriate icons on each search input -->
<div class="p-grid p-nogutter history-wrapper">
  <div class="p-lg-12 p-md-12 p-sm-12 p-nogutter">

    <h3 class="first">Search Service Calls</h3>
    <div class="filters p-d-flex" (keyup.enter)="search()">
        <div>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="fas fa-file-alt"></i></span>
            <input type="text" pInputText placeholder="Reference #" [(ngModel)]="searchParams.referenceNumber">
          </div>
        </div>
        <div>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="fas fa-file-alt"></i></span>
            <input type="text" pInputText placeholder="Work Order #" [(ngModel)]="searchParams.workOrderNumber">
          </div>
        </div>
        <div>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="fas fa-file-alt"></i></span>
            <input type="text" pInputText placeholder="DR #" [(ngModel)]="searchParams.deliveryReceiptNumber">
          </div>
        </div>
        <div>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="fas fa-file-alt"></i></span>
            <input type="text" pInputText placeholder="Unit #" [(ngModel)]="searchParams.unitNumber">
          </div>
        </div>
        <div>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="fas fa-file-alt"></i></span>
            <input type="text" pInputText placeholder="Tire Size" [(ngModel)]="searchParams.tireSize">
          </div>
        </div>
        <div>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="fas fa-file-alt"></i></span>
            <input type="text" pInputText placeholder="Wheel Position" [(ngModel)]="searchParams.tirePosition">
          </div>
        </div>
        <div>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="fas fa-calendar"></i></span>
            <p-calendar [(ngModel)]="searchParams.callStartedDate" placeholder="Date Range" showButtonBar="true" selectionMode="range" [readonlyInput]="true"></p-calendar>
          </div>
        </div>
    </div>
    <div>
      <button pButton type="button" label="Search" icon="fas fa-search" class="p-button-raised" (click)="search()"></button>
    </div>

    <div class="service-call-list">
      <div *ngIf="searched && searching" class="loading-overlay">
        <div class="p-d-flex p-flex-column p-ai-center">
          <h2 class="search-text">Searching...</h2>
          <img class="search-img" src="assets/img/STMLoadingIcon.svg" />
        </div>
      </div>
      <p-table *ngIf="searched"
        #dt
        [exportFilename]="exportFilename"
        [columns]="cols"
        [value]="tableData"
        rowHover="true"
        [paginator]="true"
        [lazy]="true"
        [totalRecords]="totalServiceCallCount"
        [rows]="pageSize"
        [rowsPerPageOptions]="[10,15,20,50,100]"
        [first]="first"
        (onPage)="paginate($event)"
        paginatorPosition="both"
        selectionMode="single"
        dataKey="referenceNumber"
        (onEditInit)="csDataEditStarted($event)"
        (onEditComplete)="csDataEditChanged($event)">
        <ng-template pTemplate="caption">
          <div class="p-grid p-nogutters">
            <div class="p-col-6 p-col-align-center" style="text-align:left;">
              Result Count: {{ tableData.length }}
            </div>
            <div class="p-col-6 p-col-align-center" style="text-align: right">
                <button type="button" pButton icon="pi pi-file-o" iconPos="left" label="Export CSV" (click)="dt.exportCSV()" style="margin-right: 0.5em;"></button>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th>
              PO Number
            </th>
            <th *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
              {{col.header}}
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr class="p-selectable-row" [ngClass]="rowData.dispatcherClass">
            <td [pEditableColumn]="rowData" [pEditableColumnField]="'customerSuppliedPONumber'">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input [(ngModel)]="rowData.customerSuppliedPONumber" style='width:100%;'/>
                </ng-template>
                <ng-template pTemplate="output">
                  <div class="p-grid">
                    <div class="p-sm-10">
                      {{rowData.customerSuppliedPONumber}}
                    </div>
                    <div class="p-sm-2">
                      <i class="fas fa-edit"></i>
                    </div>
                  </div>
                </ng-template>
              </p-cellEditor>
            </td>
            <td *ngFor="let col of columns" [ngSwitch]="col.header" [pSelectableRow]="rowData">
              <span *ngSwitchCase="'Started'" [title]="rowData[col.field]">{{rowData[col.field] | date:'MM/dd/yy'}}</span>
              <span *ngSwitchDefault>{{rowData[col.field]}}</span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <div class="messages p-d-flex p-jc-center">
      <div *ngIf="!searched && !searching" class="p-d-flex p-flex-column p-ai-center">
        <h2>Start searching for service calls by using the filter fields above.</h2>
        <img class="message-img" width="100px" src="assets/img/icons/2020_STM Portal Icons_AP - INVOICE SEARCH.svg" />
      </div>

      <div *ngIf="!searched && searching" class="p-d-flex p-flex-column p-ai-center">
        <h2 class="search-text">Searching...</h2>
        <img class="search-img" src="assets/img/STMLoadingIcon.svg" />
      </div>

      <div *ngIf="searched && !searching && serviceCalls.length <= 0" class="p-d-flex p-flex-column p-ai-center">
        <h2>Sorry we couldn't find any matches for you search criteria.</h2>
        <p>Maybe your search was too specific, please try searching with another term.</p>
        <img class="message-img" width="100px" src="assets/img/icons/2020_STM Icons_Question.svg" />
      </div>
    </div>
  </div>
</div>
