import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanAuthenticationGuard } from '../core/auth.guard';
import { LayoutComponent } from '../layout/layout/layout.component';
import { CallCenterLandingComponent } from './call-center-landing/call-center-landing.component';
import { CallCenterUploadsComponent } from './call-center-uploads/call-center-uploads.component';
import { CallCenterWorkQueueComponent } from './call-center-work-queue/call-center-work-queue.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [CanAuthenticationGuard],
    component: LayoutComponent,
    children: [
      { path: '', component: CallCenterLandingComponent },
      { path: 'uploads', component: CallCenterUploadsComponent },
      { path: 'work-queue', component: CallCenterWorkQueueComponent },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CallCenterRoutingModule {}
