export enum ImportValueType{
  String,
  Number,
  Time,
  Date,
  TimeString,
}

export interface ImportSheetType{
  name: string;
  importMappings: ImportMapping[];
}

export interface ImportMapping{
  sourceName: string;
  targetName: string;
  required: boolean;
  valueType: ImportValueType;
  index?: number;
  unique?: boolean;
}

export interface ImportData{
  importSheetType: ImportSheetType;
  data: any[];
}
