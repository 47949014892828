import { ServiceCallSearchComponent } from './service-call-search/service-call-search.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanAuthenticationGuard } from '../core/auth.guard';
import { LayoutComponent } from '../layout/layout/layout.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [CanAuthenticationGuard],
    component: LayoutComponent,
    children: [
      { path: 'search', component: ServiceCallSearchComponent },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ServiceCallsRoutingModule {}
